<template>
  <div class="page">
    <Header />
    <slot/>
    <Footer />
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Loader from "@/components/Loader";

export default {
  name: "Default",
  components: {
    Header, Footer, Loader
  },
  data () {
    return {
      isLoading: false
    }
  },
  mounted() {
    if (window.WOW) new window.WOW().init();
  },
  watch: {
    $route: function (v, old) {
      if (old && old.fullPath != v.fullPath) {
        this.handleLoader();
        window.scrollTo(0, 0);
      }
    },
  },
  methods: {
    handleLoader () {
      const self = this
      self.isLoading = true
      setTimeout(() => {
        self.isLoading = false
      }, 500)
    }
  }
}
</script>

<style scoped>
.content {
  padding: 0 15px;
  min-height: 84vh;
}
</style>
