/*
*
*/
export const setStoredSettings = ({state, effects}) => {
  const settings = effects.settingsStore.getAll();

  if (settings.keybindings) {
    settings.keybindings = Object.keys(settings.keybindings).reduce(
      (value, key) =>
        value.concat({
          key,
          bindings: settings.keybindings[key],
        }),
      []
    );
  }

  Object.assign(state.preferences.settings, settings);
};

/*
*
*/
export const setSignedInCookie = ({state}) => {
  console.log(state, 'setSignedInCookie...')
  document.cookie = 'signedIn=true; Path=/;';
};

/*
*
*/
export const addNotification = ({state}, {title, type, timeAlive, buttons}) => {
  const now = Date.now();
  const timeAliveDefault = type === 'error' ? 6 : 3;

  state.notifications.push({
    id: now,
    title,
    type,
    buttons: buttons || [],
    endTime: now + (timeAlive || timeAliveDefault) * 1000,
  });
};

/*
*
*/
const VIEW_MODE_DASHBOARD = 'VIEW_MODE_DASHBOARD';
export const setViewModeForDashboard = ({effects, state}) => {
  const localStorageViewMode = effects.browser.storage.get(VIEW_MODE_DASHBOARD);
  if (localStorageViewMode) {
    state.dashboard.viewMode = localStorageViewMode;
  }
};
