<template>
  <footer class="page-footer">
    <div class="footer-top">
      <div class="container">
        <div class="row link-wrapper">
          <div class="item col-6 col-lg">
            <h5>Industry Solutions</h5>
            <ul class="list-unstyled text-small">
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for LifeSciences'})" role="button">Revenue Intelligence for Life Sciences</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for Med Tech'})" role="button">Revenue Intelligence for Med Tech</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for BioTech'})" role="button">Revenue Intelligence for BioTech</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'VAVE for LifeSciences'})" role="button">Value Engineering and Analysis for Life Sciences</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'VAVE for Med Tech'})" role="button">Value Engineering and Analysis for Med Tech</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'VAVE for BioTech'})" role="button">Value Engineering and Analysis for Bio Tech</a></li>
              <!-- <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services'})" role="button">Revenue Intelligence Services</a></li> -->
            </ul>
          </div>
          <div class="item col-6 col-lg">
            <h5>Revenue Intelligence by Role</h5>
            <ul class="list-unstyled text-small">
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for CFO'})" role="button">Revenue Intelligence for CFO</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for HeadOfMarket'})" role="button">Revenue Intelligence for Market Access</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for ContractRO'})" role="button">Revenue Intelligence for Contract and Revenue Operations</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for FPA'})" role="button">Revenue Intelligence for FP&A</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for AccountManage'})" role="button">Revenue Intelligence for Account Management</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for BusinessAnalyst'})" role="button">Revenue Intelligence for Business Analyst</a></li>
            </ul>
          </div>
          <div class="item col-6 col-lg">
            <h5>Revenue Intelligence Solutions</h5>
            <ul class="list-unstyled text-small">
              <li class="link-item"><a @click="$router.push({name: 'Revtilize'})" role="button" class="link-footer text-decoration-none opacity-100"><img src="@/assets/images/revtilize-color.png" alt="REVtilize" class="img-hero" width="120" /></a></li>
              <li class="link-item"><a @click="$router.push({name: 'Vavetilize'})" role="button" class="link-footer text-decoration-none opacity-100 pt-1"><img src="@/assets/images/vavetilize-color.png" alt="VAVEtilize" class="img-hero" width="120" /></a></li>
              <li class="link-item"><a @click="$router.push({name: 'AIChatDriven'})" role="button" class="link-footer text-decoration-none opacity-100 pt-1"><img src="@/assets/images/altilize-color.png" alt="ALtilize" class="img-hero" width="100" /></a></li>
            </ul>
          </div>
          <div class="item col-6 col-lg">
            <h5>Revenue Intelligence Services</h5>
            <ul class="list-unstyled text-small">
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Data'})" role="button">Data Management and Bridging</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Roster'})" role="button">Membership Roster integration and automation</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services PlanPayer'})" role="button">Plan - Payer Hierarchy Management</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services UX'})" role="button">User Experience Enhancement</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services DataLake'})" role="button">Data Lake Architecture</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Capability'})" role="button">Revenue Intelligence Capability Development</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Accruals'})" role="button">Revenue and Accruals Forecasting</a></li>
              <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services GTN'})" role="button">Gross to Net Reporting and Forecasting</a></li>
            </ul>
          </div>
        </div>
        <div class="mob-link-wrapper">
          <div class="row">
            <div class="item">
              <Accordion :mode="'dark'" class="py-1">
                <template v-slot:title>
                  <span class="text-white">Industry Solutions</span>
                </template>
                <template v-slot:content>
                  <ul class="list-unstyled text-small ms-3 pt-2">
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for LifeSciences'})" role="button">Revenue Intelligence for Life Sciences</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for Med Tech'})" role="button">Revenue Intelligence for Med Tech</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for BioTech'})" role="button">Revenue Intelligence for BioTech</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'VAVE for LifeSciences'})" role="button">Value Engineering and Analysis for Life Sciences</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'VAVE for Med Tech'})" role="button">Value Engineering and Analysis for Med Tech</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'VAVE for BioTech'})" role="button">Value Engineering and Analysis for Bio Tech</a></li>
                    <!-- <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services'})" role="button">Revenue Intelligence Services</a></li> -->
                  </ul>
                </template>
              </Accordion>
              <Accordion :mode="'dark'" class="py-1 border-top">
                <template v-slot:title>
                  <span class="text-white">Revenue Intelligence by Role</span>
                </template>
                <template v-slot:content>
                  <ul class="list-unstyled text-small ms-3 pt-2">
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for CFO'})" role="button">Revenue Intelligence for CFO</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for HeadOfMarket'})" role="button">Revenue Intelligence for Market Access</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for ContractRO'})" role="button">Revenue Intelligence for Contract and Revenue Operations</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for FPA'})" role="button">Revenue Intelligence for FP&A</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for AccountManage'})" role="button">Revenue Intelligence for Account Management</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI for BusinessAnalyst'})" role="button">Revenue Intelligence for Business Analyst</a></li>
                  </ul>
                </template>
              </Accordion>
              <Accordion :mode="'dark'" class="py-1 border-top">
                <template v-slot:title>
                  <span class="text-white">Revenue Intelligence Solutions</span>
                </template>
                <template v-slot:content>
                  <ul class="list-unstyled text-small ms-3 pt-2">
                    <li class="link-item"><a class="link-footer text-decoration-none opacity-100" @click="$router.push({name: 'Revtilize'})" role="button"><img src="@/assets/images/revtilize-color.png" alt="REVtilize" class="img-hero" width="100" /></a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none opacity-100 pt-1" @click="$router.push({name: 'Vavetilize'})" role="button"><img src="@/assets/images/vavetilize-color.png" alt="VAVEtilize" class="img-hero" width="100" /></a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none opacity-100 pt-1" @click="$router.push({name: 'AIChatDriven'})" role="button"><img src="@/assets/images/altilize-color.png" alt="ALtilize" class="img-hero" width="100" /></a></li>
                  </ul>
                </template>
              </Accordion>
              <Accordion :mode="'dark'" class="py-1 border-top">
                <template v-slot:title>
                  <span class="text-white">Revenue Intelligence Services</span>
                </template>
                <template v-slot:content>
                  <ul class="list-unstyled text-small ms-3 pt-2">
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Data'})" role="button">Data Management and Bridging</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Roster'})" role="button">Membership Roster integration and automation</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services PlanPayer'})" role="button">Plan - Payer Hierarchy Management</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services UX'})" role="button">User Experience Enhancement</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services DataLake'})" role="button">Data Lake Architecture</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Capability'})" role="button">Revenue Intelligence Capability Development</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services Accruals'})" role="button">Revenue and Accruals Forecasting</a></li>
                    <li class="link-item"><a class="link-footer text-decoration-none" @click="$router.push({name: 'RI Services GTN'})" role="button">Gross to Net Reporting and Forecasting</a></li>
                  </ul>
                </template>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!--  -->
        <div class="row">
          <div class="col-lg-4 text-center text-lg-start">
            <a href="/" class="text-decoration-none">
              <img class="mb-4 mb-lg-0" src="@/assets/logo.png" alt="Logo" width="65">
            </a>
          </div>
          <div class="col-lg-8 d-flex justify-content-center justify-content-lg-end my-auto">
            <div class="subscribe-sign-up d-none">
              <form id="newslatterForm">
                <div class="w-100 justify-content-end align-items-center">
                    <span class="subscribe-label">Sign up for email updates today!</span>
                    <div class="d-inline-flex mt-2 mt-lg-0">
                      <input type="email" name="email" placeholder="Email Address" id="subscriptionEmail" class="news-text" required="">
                      <button type="submit" class="news-btn">SUBSCRIBE</button>
                    </div>
                  </div>
              </form>
            </div>
            <div>
              <div class="text-center text-lg-end">
                <span class="icon-social">
                  <a href="https://twitter.com/getGTNsmart" target="_blank">
                    <img src="@/assets/images/icons/fa-twitter.png" alt="twitter">
                  </a>
                  <a href="https://www.linkedin.com/company/fundae-inc/" target="_blank">
                    <img src="@/assets/images/icons/fa-linkedin.png" alt="linkedin">
                  </a>
                </span>
              </div>
              <div class="copyright-text mt-2">Copyright {{ year }} fundae Software Inc. All Rights Reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Accordion from './Accordion'
export default {
  components: { Accordion },
  data() {
    const d = new Date()
    return {
      name: "Footer",
      year: d.getFullYear()
    }
  },
}
</script>

<style scoped>
  .footer-top {
    background-color: #414141;
    padding: 70px 0 60px;
  }
  .footer-top .item h5 {
    padding-top: 4px;
    min-height: 46px;
    margin-bottom: 25px;
    font-weight: normal;
    font-size: 20px;
    color: #ffffff;
    border-bottom: 1px solid #979797;
  }
  .footer-top .item ul li {
    padding-top: 4px;
    padding-bottom: 14px;
    line-height: 21px;
  }
  .footer-top .item a {
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    font-size: 16px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
  .footer-top .item a:hover { color: var(--primary-color); opacity: 1; }
  .mob-link-wrapper { display: none; }

  .footer-bottom { background-color: #252525; padding: 30px 0px; }
  .subscribe-sign-up .news-text {
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    padding: 12px 20px 5px 20px;
    min-width: 275px;
    max-width: 100%;
    outline: none!important;
  }
  .subscribe-sign-up .news-btn {
    height: 50px;
    width: 150px;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    padding: 16px;
    font-weight: 400;
  }
  .subscribe-label {
    font-size: 20px !important;
    letter-spacing: 0.92px;
    color: #FFFFFF;
    margin-right: 60px;
    font-weight: 400;
  }
  .footer-bottom .icon-social a {
    margin-left: 12px;
  }
  .copyright-text {
    font-weight: 300;
    line-height: 24px;
    font-size: 15px;
    margin-top: 30px;
    color: #ffffff;
  }
  /*  */
  @media only screen and (max-width: 991px) {
    .link-wrapper { display: none; }
    .mob-link-wrapper { display: block; }
    .footer-top { padding: 30px 0px; }
  }
</style>
