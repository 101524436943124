import { graphql } from "overmind-graphql";

import * as userMutations from "./user/mutations";
import * as userQueries from "./user/queries";

export default graphql({
  subscriptions: {
  },
  queries: {
    ...userQueries
  },
  mutations: {
    ...userMutations
  },
});
