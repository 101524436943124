import { createOvermind } from "overmind";
import { merge, namespaced } from "overmind/config";
import { createPlugin } from "overmind-vue";
import { onInitialize } from "./onInitialize";
import { state } from "./state";
import * as effects from "./effects";
import * as actions from "./actions";

// custom
import * as user from "./namespaces/user";

export const config = merge(
  {
    state,
    effects,
    actions,
    onInitialize,
  },
  namespaced({
    user
  })
);

const overmind = createOvermind(config, {
  devtools: false, // defaults to localhost:3031
});

export const OvermindPlugin = createPlugin(overmind);
