<template>
  <div class="accordion" :class="mode">
    <button @click="toggleAccordion()" class="d-flex align-items-center btn btn-outline w-100" :class="{isOpen}" :aria-expanded="isOpen">
      <slot name="title" />
      <svg class="icon" :class="{ 'rotate-180': isOpen, 'rotate-0': !isOpen }"
        fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" aria-hidden="true">
        <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
    <transition name="accordion" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
      <div class="body" v-show="isOpen">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'Accordion',
    props: ['mode'],
    data() {
      return {
        isOpen: false,
      }
    },
    methods: {
      toggleAccordion() {
        this.isOpen = !this.isOpen;
      },
      beforeEnter(el) {
        if (el) el.style.height = '0';
      },
      enter(el) {
        if (el) el.style.height = el.scrollHeight + 'px';
      },
      beforeLeave(el) {
        if (el) el.style.height = el.scrollHeight + 'px';
      },
      leave(el) {
        if (el) el.style.height = '0';
      }
    }
  };
</script>

<style scoped>
  button.btn:hover {
    border-color: transparent; box-shadow: none;
  }
  .accordion:not(.dark) button.isOpen { background-color: #d8d8d8; }
  .accordion.dark .icon { color: #ffffff; }
  
  .icon { width: 15px; margin-left: auto; transition: 0.5s all; transform: rotate(0deg); }
  .icon.rotate-180 { transform: rotate(180deg); }

  .accordion .body { transition: 150ms ease-out; }
</style>