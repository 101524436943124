<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
const default_layout = 'default';

export default {
  name: "app",
  computed: {
    layout() {
      // console.log(this.$route.meta.layout, 'layout')
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
}
</script>