// import { json } from "overmind";

/*
*
*/
export const getTotalUsers = async ({ state, effects }) => {
  const { users } = await effects.gql.queries.users();
  state.user.totalRecords = users ? users.length : 0;
}

/*
*
*/
export const getUsers = async ({ state, effects }, data) => {
  try {
    let options = {}
    if (data) options = data
    const { users } = await effects.gql.queries.users(options)
    if (data.getValues) {
      return users
    } else {
      state.user.users = users;
    }
  } catch (e) {
    console.log(e, 'getUsers errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
}

/*
*
*/
export const onChangePage = async ({ state }, page) => {
  state.user.activePage = page
}

/*
*
*/
export const onUserAdded = ({ state }, data) => {
  state.user.push(data)
}
