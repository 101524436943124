import { createApp } from 'vue'
import App from './App.vue'

import { OvermindPlugin } from "@/store";
import router from "./router";
// CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/univers-lt-std.css";
import "./assets/css/animate.css"
import "./main.css";

/* Globally register layout component */
import Default from "./layouts/Default";
import Blank from "./layouts/Blank";

createApp(App)
    .use(router)
    .use(OvermindPlugin)
    .component("default-layout", Default)
    .component("blank-layout", Blank)
    .mount('#app');
