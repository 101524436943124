import gql from 'graphql-tag';
import { userFragment, AuthPayLoad } from './fragments';

/*
*
*/
export const saveUser = gql`
  mutation saveUser($data: UserUpdateInput!, $where: UserWhereUniqueInput) {
    saveUser(data: $data, where: $where) ${userFragment}
  }
`;

/*
*
*/
export const deleteUser = gql`
  mutation deleteUser($where: UserWhereUniqueInput) {
    deleteUser(where: $where) ${userFragment}
  }
`;

/*
*
*/
export const createAppUser = gql`
  mutation createAppUser($mobileNumber: String! $password: String $generateToken: Boolean $disableSendingSmsCode: Boolean, $appVersion: String) {
    createAppUser(generateToken: $generateToken password: $password mobileNumber: $mobileNumber disableSendingSmsCode: $disableSendingSmsCode appVersion: $appVersion) ${AuthPayLoad}
  }
`;

/*
*
*/
export const login = gql`
  mutation login($email:String! $password:String!) {
    login(email:$email password:$password) {
      token
      user ${userFragment}
    }
  }
`;

/*
*
*/
export const loginWithToken = gql`
  mutation loginWithToken($token:String! $userId:String! $appVersion:String) {
    loginWithToken(token:$token userId:$userId appVersion:$appVersion) {
      user ${userFragment}
    }
  }
`;

