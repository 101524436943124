module.exports = {
  Settings: {
    appName: "fundae",
    appVersion: "1.0",
    mainDomain: "fundae.io",
    groupDomain: "fundae-io",
    siteId: "641f5e1e24aa9a0008430e88",
    mainUrl: "https://fundae.io/#/",
    gqlServer: {
      url: "http://localhost:4001",
      ws: "wss://api-staging.companycheckout.com"
    }
  },
};
