import { createRouter, createWebHistory } from 'vue-router'
// createWebHashHistory

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home"),
    meta: {
      isFullPage: true,
      isDark: true,
    }
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("@/pages/Login"),
  //   meta: {
  //     layout: 'blank'
  //   }
  // },
  // Industry Solutions
  {
    path: "/industries/life-sciences",
    name: "RI for LifeSciences",
    component: () => import("@/pages/Industry/LifeSciences"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/industries/medtech",
    name: "RI for Med Tech",
    component: () => import("@/pages/Industry/MedTech"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/industries/biotech",
    name: "RI for BioTech",
    component: () => import("@/pages/Industry/BioTech"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/industries/vave-life-sciences",
    name: "VAVE for LifeSciences",
    component: () => import("@/pages/Industry/VAVELifeSciences"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/industries/vave-medtech",
    name: "VAVE for Med Tech",
    component: () => import("@/pages/Industry/VAVEMedTech"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/industries/vave-biotech",
    name: "VAVE for BioTech",
    component: () => import("@/pages/Industry/VAVEBioTech"),
    meta: {
      layout: 'default'
    }
  },
  // {
  //   path: "/industries/services/",
  //   name: "RI Services",
  //   component: () => import("@/pages/Page"),
  //   meta: {
  //     layout: 'default'
  //   }
  // },
  // Revenue Intelligence Solution
  {
    path: "/solution/rev",
    name: "Revtilize",
    component: () => import("@/pages/Solution/Rav"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/solution/vave",
    name: "Vavetilize",
    component: () => import("@/pages/Solution/Vave"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/solution/ai",
    name: "AIChatDriven",
    component: () => import("@/pages/Solution/AI"),
    meta: {
      layout: 'default'
    }
  },
  // Revenue Intelligence - Role
  {
    path: "/revenue/cfo",
    name: "RI for CFO",
    component: () => import("@/pages/RI/CFO"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/revenue/head-of-market",
    name: "RI for HeadOfMarket",
    component: () => import("@/pages/RI/HeadOfMarket"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/revenue/contract-ro",
    name: "RI for ContractRO",
    component: () => import("@/pages/RI/ContractRO"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/revenue/fpa",
    name: "RI for FPA",
    component: () => import("@/pages/RI/FPA"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/revenue/account-management",
    name: "RI for AccountManage",
    component: () => import("@/pages/RI/AccountManage"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/revenue/business-analyst",
    name: "RI for BusinessAnalyst",
    component: () => import("@/pages/RI/BusinessAnalyst"),
    meta: {
      layout: 'default'
    }
  },
  // Revenue Intelligence Services
  {
    path: "/services/data",
    name: "RI Services Data",
    component: () => import("@/pages/Services/Data"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/roster",
    name: "RI Services Roster",
    component: () => import("@/pages/Services/Roster"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/plan-payer",
    name: "RI Services PlanPayer",
    component: () => import("@/pages/Services/PlanPayer"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/ux",
    name: "RI Services UX",
    component: () => import("@/pages/Services/UX"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/data-lake",
    name: "RI Services DataLake",
    component: () => import("@/pages/Services/DataLake"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/capability",
    name: "RI Services Capability",
    component: () => import("@/pages/Services/Capability"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/accruals",
    name: "RI Services Accruals",
    component: () => import("@/pages/Services/Accruals"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/services/gtn",
    name: "RI Services GTN",
    component: () => import("@/pages/Services/GTN"),
    meta: {
      layout: 'default'
    }
  },
  // Resources
  {
    path: "/resources/blog",
    name: "Blog",
    component: () => import("@/pages/Resource/Blog"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/resources/blog/:slug",
    name: "Blog Detail",
    component: () => import("@/pages/Resource/BlogDetail"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/resources/whitepaper",
    name: "Whitepaper",
    component: () => import("@/pages/Resource/WhitePaper"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/resources/whitepaper/:slug",
    name: "Whitepaper Detail",
    component: () => import("@/pages/Resource/WhitePaperDetail"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/resources/case-study",
    name: "Case Study",
    component: () => import("@/pages/Resource/CaseStudy"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/resources/case-study/:slug",
    name: "Case Study Detail",
    component: () => import("@/pages/Resource/CaseStudyDetail"),
    meta: {
      layout: 'default'
    }
  },
  // Company
  {
    path: "/company/about",
    name: "About",
    component: () => import("@/pages/About"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/company/team",
    name: "Team",
    component: () => import("@/pages/Company/Team"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/company/locations",
    name: "Locations",
    component: () => import("@/pages/Company/Locations"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/company/careers",
    name: "Careers",
    component: () => import("@/pages/Company/Careers"),
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/company/support-portal",
    name: "Support Portal",
    component: () => import("@/pages/Company/SupportPortal"),
    meta: {
      layout: 'default'
    }
  },
  //
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/pages/NotFound.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
})

export default router
