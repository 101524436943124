<template>
  <div class="top-section" :class="{ sticking }">
    <div class="utility-nav" ref="target">
      <ul class="container">
        <li id="navItem1" class="nav-item"><a @click="$router.push({name: 'Home'})" role="button">Home</a></li>
        <li id="navItem2" class="nav-item"><a href="/company/careers/">Careers</a></li>
        <li id="navItem3" class="nav-item"><a href="/company/support-portal">Support Portal</a></li>
      </ul>
    </div>
    <div class="header-bottom border-bottom" :class="{ sticking }">
      <div class="container">
        <header class="d-flex flex-wrap justify-content-center align-items-center position-relative">
          <a href="/" class="d-flex align-items-center mb-0 me-auto text-decoration-none">
            <img src="@/assets/logo.png" alt="Logo" class="img-logo" />
          </a>
          <ul class="nav nav-pills main-menu">
            <!--<li class="nav-item"><a @click="$router.push({name: 'Home'})" role="button" class="nav-link" :class="$route.name == 'Home' ? 'active' : ''" aria-current="page">Home</a></li>-->
            <li class="nav-item menu-item" :class="{ 'active': industryRouteActive }">
              <span class="nav-link" role="button">Industry Solutions</span>
              <ul class="sub-menu d-flex sub-col-2">
                <li class="left-menu">
                  <ul class="sub-list">
                    <li class="menu-item"><a @click="$router.push({name: 'RI for LifeSciences'})" role="button" class="">Revenue Intelligence for Life Sciences</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for Med Tech'})" role="button" class="">Revenue Intelligence for Med Tech</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for BioTech'})" role="button" class="">Revenue Intelligence for BioTech</a></li>
                  </ul>
                </li>
                <li class="right-menu">
                  <ul class="sub-list">
                    <li class="menu-item"><a @click="$router.push({name: 'VAVE for LifeSciences'})" role="button" class="">Value Engineering and Analysis for Life Sciences</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'VAVE for Med Tech'})" role="button" class="">Value Engineering and Analysis for Med Tech</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'VAVE for BioTech'})" role="button" class="">Value Engineering and Analysis for Bio Tech</a></li>
                    <!-- <li class="menu-item"><a @click="$router.push({name: 'RI Services'})" role="button" class="">Revenue Intelligence Services</a></li> -->
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item menu-item" :class="{ 'active': riRouteActive }">
              <span class="nav-link" role="button">Revenue Intelligence</span>
              <ul class="sub-menu d-flex sub-col-2" id="RISubMenu">
                <li class="left-menu">
                  <span class="sub-item-title">Revenue Intelligence Solutions</span>
                  <ul class="sub-list">
                    <li class="menu-item"><a @click="$router.push({name: 'Revtilize'})" role="button" class=""><img src="@/assets/images/revtilize.png" alt="REVtilize" class="img-hero" width="100" /></a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'Vavetilize'})" role="button" class="pt-1"><img src="@/assets/images/vavetilize.png" alt="VAVEtilize" class="img-hero" width="100" /></a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'AIChatDriven'})" role="button" class="pt-1"><img src="@/assets/images/altilize.png" alt="ALtilize" class="img-hero" width="100" /></a></li>
                  </ul>
                </li>
                <li class="right-menu">
                  <span class="sub-item-title">Revenue Intelligence By Role</span>
                  <ul class="sub-list">
                    <li class="menu-item"><a @click="$router.push({name: 'RI for CFO'})" role="button" class="">Revenue Intelligence for CFO</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for HeadOfMarket'})" role="button" class="">Revenue Intelligence for Head of Market Access</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for ContractRO'})" role="button" class="">Revenue Intelligence for Contract and Revenue Operations</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for FPA'})" role="button" class="">Revenue Intelligence for FP&A</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for AccountManage'})" role="button" class="">Revenue Intelligence for Account Management</a></li>
                    <li class="menu-item"><a @click="$router.push({name: 'RI for BusinessAnalyst'})" role="button" class="">Revenue Intelligence for Business Analyst</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item menu-item" :class="{ 'active': serviceRouteActive }">
              <span class="nav-link" role="button">Revenue Intelligence Services</span>
              <ul class="sub-menu">
                <li class="menu-item"><a @click="$router.push({name: 'RI Services Data'})" role="button" class="">Data Management and Bridging</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services Roster'})" role="button" class="">Membership Roster integration and automation</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services PlanPayer'})" role="button" class="">Plan - Payer Hierarchy Management</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services UX'})" role="button" class="">User Experience Enhancement</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services DataLake'})" role="button" class="">Data Lake Architecture</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services Capability'})" role="button" class="">Revenue Intelligence Capability Development</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services Accruals'})" role="button" class="">Revenue and Accruals Forecasting</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'RI Services GTN'})" role="button" class="">Gross to Net Reporting and Forecasting</a></li>
              </ul>
            </li>
            <li class="nav-item menu-item" :class="{ 'active': resourceRouteActive }">
              <span class="nav-link" role="button">Resources</span>
              <ul class="sub-menu">
                <li class="menu-item"><a @click="$router.push({name: 'Blog'})" role="button" class="">Blogs</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'Whitepaper'})" role="button" class="">Whitepapers</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'Case Study'})" role="button" >Case Studies</a></li>
              </ul>
            </li>
            <li class="nav-item menu-item" :class="{ 'active': companyRouteActive }">
              <span class="nav-link" role="button">Company</span>
              <ul class="sub-menu">
                <li class="menu-item"><a @click="$router.push({name: 'About'})" role="button" class="">About Us</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'Team'})" role="button" class="">Team</a></li>
                <li class="menu-item"><a @click="$router.push({name: 'Locations'})" role="button" class="">Locations</a></li>
                <li class="menu-item"><a href="https://survey.zohopublic.com/zs/3tCCgn" target="_blank" class="">Contact Us</a></li>
              </ul>
            </li>
            <li class="nav-item ms-xl-3 ms-lg-1 pt-1 d-flex align-items-center">
              <a href="https://survey.zohopublic.com/zs/3tCCgn" target="_blank" role="button" class="btn btn-mt-primary btn-contact">Contact Sales</a>
            </li>
          </ul>
          <div id="navIconMob" role="button" class="btn-mob-menu d-none" :class="{ 'open': isMenuOpen }" @click="isMenuOpen = !isMenuOpen">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="mobile-menu-wrapper" v-if="isMenuOpen" :class="{ 'active': isMenuOpen }">
            <div class="container py-2">
              <Accordion class="py-1">
                <template v-slot:title>
                  <span class="">Industry Solutions</span>
                </template>
                <template v-slot:content>
                  <ul class="list-group list-group-flush ms-2">
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for LifeSciences'})" role="button" class="">Revenue Intelligence for Life Sciences</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for Med Tech'})" role="button" class="">Revenue Intelligence for Med Tech</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for BioTech'})" role="button" class="">Revenue Intelligence for BioTech</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'VAVE for LifeSciences'})" role="button" class="">Value Engineering and Analysis for Life Sciences</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'VAVE for Med Tech'})" role="button" class="">Value Engineering and Analysis for Med Tech</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'VAVE for BioTech'})" role="button" class="">Value Engineering and Analysis for Bio Tech</a></li>
                    <!-- <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services'})" role="button" class="">Revenue Intelligence Services</a></li> -->
                  </ul>
                </template>
              </Accordion>
              <Accordion class="py-1">
                <template v-slot:title>
                  <span class="">Revenue Intelligence</span>
                </template>
                <template v-slot:content>
                  <ul class="list-group list-group-flush ms-2">
                    <li class="list-group-item menu-item">
                      <div class="sub-menu"><span class="sub-item-title ps-0 my-0">Revenue Intelligence Solutions</span></div>
                      <ul class="sub-list list-group list-group-flush">
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'Revtilize'})" role="button" class=""><img src="@/assets/images/revtilize.png" alt="REVtilize" class="img-hero" width="100" /></a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'Vavetilize'})" role="button" class="pt-1"><img src="@/assets/images/vavetilize.png" alt="VAVEtilize" class="img-hero" width="100" /></a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'AIChatDriven'})" role="button" class="pt-1"><img src="@/assets/images/altilize.png" alt="ALtilize" class="img-hero" width="100" /></a></li>
                      </ul>
                    </li>
                    <li class="list-group-item menu-item mt-2">
                      <div class="sub-menu"><span class="sub-item-title ps-0 my-0">Revenue Intelligence By Role</span></div>
                      <ul class="sub-list list-group list-group-flush">
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for CFO'})" role="button" class="">Revenue Intelligence for CFO</a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for HeadOfMarket'})" role="button" class="">Revenue Intelligence for Head of Market Access</a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for ContractRO'})" role="button" class="">Revenue Intelligence for Contract and Revenue Operations</a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for FPA'})" role="button" class="">Revenue Intelligence for FP&A</a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for AccountManage'})" role="button" class="">Revenue Intelligence for Account Management</a></li>
                        <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI for BusinessAnalyst'})" role="button" class="">Revenue Intelligence for Business Analyst</a></li>
                      </ul>
                    </li>
                  </ul>
                </template>
              </Accordion>
              <Accordion class="py-1">
                <template v-slot:title>
                  <span class="">Revenue Intelligence Services</span>
                </template>
                <template v-slot:content>
                  <ul class="list-group list-group-flush ms-2">
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services Data'})" role="button" class="">Data Management and Bridging</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services Roster'})" role="button" class="">Membership Roster integration and automation</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services PlanPayer'})" role="button" class="">Plan - Payer Hierarchy Management</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services UX'})" role="button" class="">User Experience Enhancement</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services DataLake'})" role="button" class="">Data Lake Architecture</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services Capability'})" role="button" class="">Revenue Intelligence Capability Development</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services Accruals'})" role="button" class="">Revenue and Accruals Forecasting</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'RI Services GTN'})" role="button" class="">Gross to Net Reporting and Forecasting</a></li>
                  </ul>
                </template>
              </Accordion>
              <Accordion class="py-1">
                <template v-slot:title>
                  <span class="">Resources</span>
                </template>
                <template v-slot:content>
                  <ul class="list-group list-group-flush ms-2">
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'Blog'})" role="button" class="">Blogs</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'Whitepaper'})" role="button" class="">Whitepapers</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'Case Study'})" role="button" class="">Case Studies</a></li>
                  </ul>
                </template>
              </Accordion>
              <Accordion class="py-1">
                <template v-slot:title>
                  <span class="">Company</span>
                </template>
                <template v-slot:content>
                  <ul class="list-group list-group-flush ms-2">
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'About'})" role="button" class="">About Us</a></li>
                    <li class="list-group-item menu-item"><a @click="$router.push({name: 'Team'})" role="button" class="">Team</a></li>
                    <li class="list-group-item menu-item"><a href="#" class="">Locations</a></li>
                    <li class="list-group-item menu-item"><a href="https://survey.zohopublic.com/zs/3tCCgn" target="_blank" class="">Contact Us</a></li>
                  </ul>
                </template>
              </Accordion>
            </div>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, toRefs, reactive } from 'vue';
import Accordion from './Accordion'

export default {
  components: {
    Accordion
  },
  watch: {
    $route: function (v) {
      if (v) {
        this.isMenuOpen = false
        if (['RI for LifeSciences', 'RI for Med Tech', 'RI for BioTech', 'VAVE for LifeSciences', 'VAVE for Med Tech', 'VAVE for BioTech'].indexOf(v.name) != -1) {
          this.industryRouteActive = true
          // 
          this.riRouteActive = false
          this.serviceRouteActive = false
          this.resourceRouteActive = false
          this.companyRouteActive = false
        } else if (['Revtilize', 'Vavetilize', 'AIChatDriven', 'RI for CFO', 'RI for HeadOfMarket', 'RI for ContractRO', 'RI for FPA', 'RI for AccountManage', 'RI for BusinessAnalyst'].indexOf(v.name) != -1) {
          this.riRouteActive = true
          // 
          this.industryRouteActive = false
          this.serviceRouteActive = false
          this.resourceRouteActive = false
          this.companyRouteActive = false
        } else if (['RI Services Data', 'RI Services Roster', 'RI Services PlanPayer', 'RI Services UX', 'RI Services DataLake', 'RI Services Capability', 'RI Services Accruals', 'RI Services GTN'].indexOf(v.name) != -1) {
          this.serviceRouteActive = true
          // 
          this.industryRouteActive = false
          this.riRouteActive = false
          this.resourceRouteActive = false
          this.companyRouteActive = false
        } else if (['Blog', 'Blog Detail', 'Whitepaper', 'Whitepaper Detail', 'Case Study', 'Case Study Detail'].indexOf(v.name) != -1) {
          this.resourceRouteActive = true
          // 
          this.industryRouteActive = false
          this.riRouteActive = false
          this.serviceRouteActive = false
          this.companyRouteActive = false
        } else if (['About', 'Team'].indexOf(v.name) != -1) {
          this.companyRouteActive = true
          // 
          this.industryRouteActive = false
          this.riRouteActive = false
          this.serviceRouteActive = false
          this.resourceRouteActive = false
        } else {
          this.industryRouteActive = false
          this.riRouteActive = false
          this.serviceRouteActive = false
          this.resourceRouteActive = false
          this.companyRouteActive = false
        }
      }
    }
  },
  setup() {
    const data = reactive({
      isMenuOpen: false,
      industryRouteActive: false,
      riRouteActive: false,
      serviceRouteActive: false,
      resourceRouteActive: false,
      companyRouteActive: false
    })
    // 
    const target = ref();
    const sticking = ref(false);
    const observer = new IntersectionObserver(
      ([entry]) => {
        sticking.value = !entry.isIntersecting; 
      },
      { threshold: 0.0 }
    );
    onMounted(() => {
      observer.observe(target.value);
    });
    return {
      target,
      sticking,
      ...toRefs(data)
    };
  }
}
</script>

<style scoped>
  .top-section { position: relative; z-index: 11; }
  .utility-nav { position: relative; }
  .utility-nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-end;
    padding: 15px 25px 0px;
    transition: 0.3s all ease-in;
    background: #fff;
    position: relative;
    z-index: 2;
    margin-bottom: 0px;
  }
  .utility-nav ul li:not(:first-child) {
    margin-left: 37px;
  }
  .utility-nav ul li > a {
    font-size: 13px;
    font-weight: 500;
    line-height: 2;
    color: var(--body-color);
  }
  .utility-nav ul li > a:hover {
    color: var(--primary-color);
  }
  /* */
  .top-section.sticking { padding-bottom: 70px; }
  .header-bottom.sticking { position: fixed; width: 100%; top: 0px; z-index: 111; background: #ffffff; }
  .img-logo { width: 75px; }
  .main-menu .menu-item { position: relative; }
  .main-menu .menu-item .nav-link {
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    font-size: 16px;
    text-align: center;
    color: var(--body-color);
    border-top: 5px solid white;
    padding: 20px 20px;
    text-transform: capitalize;
    border-radius: 0px;
  }
  .main-menu .menu-item:hover .nav-link, .main-menu .menu-item.active .nav-link {
    border-top: 5px solid var(--primary-color);
    background: #f8f8f8;
  }
  .main-menu .sub-menu {
    max-height: 0;
    overflow: hidden;
    max-width: 1440px;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.4s;
    opacity: 0;
    z-index: 1;
    min-width: 250px;
    margin: 0px;
    padding: 0px;
  }
  .main-menu .sub-menu.sub-col-2 { min-width: 600px; }
  #RISubMenu { min-width: 750px; }
  #RISubMenu .right-menu { border-left: 1px solid #d8d8d8; }
  .main-menu .sub-menu .sub-list { padding: 0px; }
  .main-menu .sub-menu .left-menu, .main-menu .sub-menu .right-menu { width: 50%; margin: 0px; padding: 5px 10px; }
  .menu-item.open .sub-menu, .menu-item:hover .sub-menu {
    max-height: calc(100vh - 90px);
    opacity: 1;
    overflow: visible;
  }
  .main-menu .sub-menu::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 50%;
    top: 0;
    background: #f8f8f8;
    height: 100%;
    z-index: -1;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
    -moz-transform: translate(-50%);
    transform: translate(-50%);
    box-shadow: 0 15px 27px rgba(0, 0, 0, 0.25);
  }
  .sub-menu .sub-item-title {
    font-weight: bold;
    line-height: 24px;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 12px;
    color: var(--secondary-color);
    display: block;
    margin: 12px 0px;
  }
  .sub-menu .menu-item { padding: 0px 15px; }
  .sub-menu .menu-item a {
    font-weight: 300;
    line-height: 20px;
    font-size: 16px;
    color: var(--body-color);
    display: block;
  }
  .sub-menu .menu-item a:hover { color: var(--primary-color); }
  .main-menu ul li {
    list-style: none;
    margin: 12px 0px;
  }
  .btn-contact { border-radius: 0px; letter-spacing: 2px; font-size: 14px; text-transform: uppercase; padding: 12px 15px 8px 15px; }
  .btn-contact:hover { box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15); }
  #navIconMob {
    width: 30px; height: 21px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    float: right;
  }
  #navIconMob span {
    display: block;
    position: absolute;
    height: 3px;
    width: 30px;
    background: #0b588e;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  #navIconMob span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }
  #navIconMob span:nth-child(2) {
    top: 50%;
    transform-origin: left center;
    transform: translateY(-50%);
  }
  #navIconMob span:nth-child(3) {
    bottom: 0;
    transform-origin: left center;
  }
  #navIconMob.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -2px; left: 8px;
  }
  #navIconMob.open span:nth-child(2) {
    width: 0%; opacity: 0;
  }
  #navIconMob.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 19px; left: 8px;
  }
  .mobile-menu-wrapper {
    box-shadow: inset 0 8px 30px #ececec;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 5;
    top: 65px;
    background: #ffffff;
    overflow: auto;
    transition: 0.5s all;
    max-height: calc(100vh - 65px);
  }

  @media only screen and (min-width: 992px) and (max-width: 1399px) {
    .main-menu .menu-item .nav-link { padding: 20px 10px; font-size: 14px; }
    .btn-contact { padding: 9px 12px 6px; letter-spacing: 1px; }
  }

  @media only screen and (max-width: 991px) {
    .nav.main-menu { display: none; }
    .btn-mob-menu { display: block !important; }
    .mobile-menu-wrapper.active { display: block; }
  }
</style>
