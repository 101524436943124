<template>
	<div class="clearfix">
		<slot/>
	</div>
</template>

<script>

  export default {
    name: "Blank",
    watch: {
      $route: function () {
        window.scrollTo(0, 0);
      },
    },
  };
</script>

<style>
	.main-content { min-height: 45vh; }
</style>
